interface CountryOption {
    name: string;
    abbreviation: string;
}

// https://www.iban.com/country-codes
export const COUNTRIES: CountryOption[] = [
    { name: "United States of America", abbreviation: "USA" },
    { name: "Canada", abbreviation: "CAN" },
];

interface StateOption {
    name: string;
    abbreviation: string;
    country: string;
}

export const STATES: StateOption[] = [
    // US: https://about.usps.com/who/profile/history/state-abbreviations.htm
    { name: "AL - Alabama", abbreviation: "AL", country: "USA" },
    { name: "AK - Alaska", abbreviation: "AK", country: "USA" },
    { name: "AZ - Arizona", abbreviation: "AZ", country: "USA" },
    { name: "AR - Arkansas", abbreviation: "AR", country: "USA" },
    { name: "AS - American Samoa", abbreviation: "AS", country: "USA" },
    { name: "CA - California", abbreviation: "CA", country: "USA" },
    { name: "CO - Colorado", abbreviation: "CO", country: "USA" },
    { name: "CT - Connecticut", abbreviation: "CT", country: "USA" },
    { name: "DC - District of Columbia", abbreviation: "DC", country: "USA" },
    { name: "DE - Delaware", abbreviation: "DE", country: "USA" },
    { name: "FL - Florida", abbreviation: "FL", country: "USA" },
    { name: "GA - Georgia", abbreviation: "GA", country: "USA" },
    { name: "HI - Hawaii", abbreviation: "HI", country: "USA" },
    { name: "ID - Idaho", abbreviation: "ID", country: "USA" },
    { name: "IL - Illinois", abbreviation: "IL", country: "USA" },
    { name: "IN - Indiana", abbreviation: "IN", country: "USA" },
    { name: "IA - Iowa", abbreviation: "IA", country: "USA" },
    { name: "KS - Kansas", abbreviation: "KS", country: "USA" },
    { name: "KY - Kentucky", abbreviation: "KY", country: "USA" },
    { name: "LA - Louisiana", abbreviation: "LA", country: "USA" },
    { name: "ME - Maine", abbreviation: "ME", country: "USA" },
    { name: "MD - Maryland", abbreviation: "MD", country: "USA" },
    { name: "MA - Massachusetts", abbreviation: "MA", country: "USA" },
    { name: "MI - Michigan", abbreviation: "MI", country: "USA" },
    { name: "MN - Minnesota", abbreviation: "MN", country: "USA" },
    { name: "MS - Mississippi", abbreviation: "MS", country: "USA" },
    { name: "MO - Missouri", abbreviation: "MO", country: "USA" },
    { name: "MT - Montana", abbreviation: "MT", country: "USA" },
    { name: "NE - Nebraska", abbreviation: "NE", country: "USA" },
    { name: "NV - Nevada", abbreviation: "NV", country: "USA" },
    { name: "NH - New Hampshire", abbreviation: "NH", country: "USA" },
    { name: "NJ - New Jersey", abbreviation: "NJ", country: "USA" },
    { name: "NM - New Mexico", abbreviation: "NM", country: "USA" },
    { name: "NY - New York", abbreviation: "NY", country: "USA" },
    { name: "NC - North Carolina", abbreviation: "NC", country: "USA" },
    { name: "ND - North Dakota", abbreviation: "ND", country: "USA" },
    {
        name: "MP - Northern Mariana Islands",
        abbreviation: "MP",
        country: "USA",
    },
    { name: "OH - Ohio", abbreviation: "OH", country: "USA" },
    { name: "OK - Oklahoma", abbreviation: "OK", country: "USA" },
    { name: "OR - Oregon", abbreviation: "OR", country: "USA" },
    { name: "PA - Pennsylvania", abbreviation: "PA", country: "USA" },
    { name: "PR - Puerto Rico", abbreviation: "PR", country: "USA" },
    { name: "RI - Rhode Island", abbreviation: "RI", country: "USA" },
    { name: "SC - South Carolina", abbreviation: "SC", country: "USA" },
    { name: "SD - South Dakota", abbreviation: "SD", country: "USA" },
    { name: "TN - Tennessee", abbreviation: "TN", country: "USA" },
    { name: "TX - Texas", abbreviation: "TX", country: "USA" },
    {
        name: "UM - United States Minor Outlying Islands",
        abbreviation: "UM",
        country: "USA",
    },
    { name: "UT - Utah", abbreviation: "UT", country: "USA" },
    { name: "VT - Vermont", abbreviation: "VT", country: "USA" },
    { name: "VI - Virgin Islands", abbreviation: "VI", country: "USA" },
    { name: "VA - Virginia", abbreviation: "VA", country: "USA" },
    { name: "WA - Washington", abbreviation: "WA", country: "USA" },
    { name: "WV - West Virginia", abbreviation: "WV", country: "USA" },
    { name: "WI - Wisconsin", abbreviation: "WI", country: "USA" },
    { name: "WY - Wyoming", abbreviation: "WY", country: "USA" },
    { name: "AA - Armed Forces Americas", abbreviation: "AA", country: "USA" },
    { name: "AE - Armed Forces Africa", abbreviation: "AE", country: "USA" },
    { name: "AE - Armed Forces Canada", abbreviation: "AE", country: "USA" },
    { name: "AE - Armed Forces Europe", abbreviation: "AE", country: "USA" },
    {
        name: "AE - Armed Forces Middle East",
        abbreviation: "AE",
        country: "USA",
    },
    { name: "AP - Armed Forces Pacific", abbreviation: "AP", country: "USA" },

    // Canada: https://www12.statcan.gc.ca/census-recensement/2021/ref/dict/tab/index-eng.cfm?ID=t1_8
    { name: "AB - Alberta", abbreviation: "AB", country: "CAN" },
    { name: "BC - British Columbia", abbreviation: "BC", country: "CAN" },
    { name: "MB - Manitoba", abbreviation: "MB", country: "CAN" },
    { name: "NB - New Brunswick", abbreviation: "NB", country: "CAN" },
    {
        name: "NL - Newfoundland and Labrador",
        abbreviation: "NL",
        country: "CAN",
    },
    { name: "NS - Nova Scotia", abbreviation: "NS", country: "CAN" },
    { name: "ON - Ontario", abbreviation: "ON", country: "CAN" },
    { name: "PE - Prince Edward Island", abbreviation: "PE", country: "CAN" },
    { name: "QC - Quebec", abbreviation: "QC", country: "CAN" },
    { name: "SK - Saskatchewan", abbreviation: "SK", country: "CAN" },
    { name: "NT - Northwest Territories", abbreviation: "NT", country: "CAN" },
    { name: "NU - Nunavut", abbreviation: "NU", country: "CAN" },
    { name: "YT - Yukon", abbreviation: "YT", country: "CAN" },
];

export const GENDER_OPTIONS: { label: string; value: string }[] = [
    {
        label: "Female",
        value: "Female",
    },
    {
        label: "Male",
        value: "Male",
    },
    {
        label: "Non-binary/Non-conforming",
        value: "Non-binary",
    },
    {
        label: "Prefer not to respond",
        value: "No response",
    },
];

export const USER_TAG_OPTIONS = ["Player", "Pro", "GM", "Other"];
