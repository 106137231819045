import * as React from "react";

import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import type { CursorResult, LocationWithRelations } from "@volley/data";

import { fetchApi } from "../../util";
import { logFetchError } from "../../util/fetchApi";
import useDebounce from "../hooks/useDebounce";

interface Props {
    homeLocationId: number | null;
    onChange: (homeLocation: LocationWithRelations | null) => void;
    prependOther?: boolean;
}

const OTHER_LOCATION = {
    name: "Other",
    homeLocation: { id: -1 },
} as unknown as LocationWithRelations;

function getOptionLabel(location: LocationWithRelations): string {
    if (!location.mailingAddress) {
        return location.name;
    }
    return `${location.name} (${location.mailingAddress.city}, ${location.mailingAddress.state})`;
}

export default function HomeLocationAutocomplete({
    homeLocationId,
    onChange,
    prependOther = false,
}: Props): React.JSX.Element {
    const [inputValue, setInputValue] = React.useState("");
    const debouncedInputValue = useDebounce(inputValue, 400);
    const [loading, setLoading] = React.useState(false);
    const [homeLocations, setHomeLocations] = React.useState<
        readonly LocationWithRelations[]
    >([]);
    const [value, setValue] = React.useState<LocationWithRelations | null>(
        null,
    );

    React.useEffect(() => {
        async function fetchHomeLocations() {
            setLoading(true);
            try {
                const res = await fetchApi<CursorResult<LocationWithRelations>>(
                    `/api/locations/home-locations?take=10&q=${debouncedInputValue.trim()}`,
                );
                const prepends =
                    prependOther && !res.result.length ? [OTHER_LOCATION] : [];
                setHomeLocations([...prepends, ...res.result]);
            } finally {
                setLoading(false);
            }
        }

        fetchHomeLocations().catch((err: Error) => logFetchError(err));
    }, [prependOther, debouncedInputValue]);

    React.useEffect(() => {
        async function fetchLocation() {
            setLoading(true);
            try {
                const res = await fetchApi<LocationWithRelations | null>(
                    `/api/locations/home-locations/${homeLocationId}`,
                );
                setValue(res);
            } finally {
                setLoading(false);
            }
        }

        if (!value && homeLocationId) {
            fetchLocation().catch((err: Error) => logFetchError(err));
        }
    }, [homeLocationId, value]);

    return (
        <Autocomplete
            id="home-court"
            fullWidth
            autoComplete
            loading={loading}
            options={value ? [value, ...homeLocations] : homeLocations}
            filterSelectedOptions
            isOptionEqualToValue={(o, v) => o.id === v.id}
            getOptionLabel={getOptionLabel}
            filterOptions={(v) => v}
            noOptionsText="Begin typing to search"
            value={value}
            onChange={(_event, newValue: LocationWithRelations | null) => {
                const nextValue =
                    newValue?.homeLocation?.id === -1 ? null : newValue;
                onChange(nextValue);
                setValue(newValue);
            }}
            onInputChange={(_, v) => setInputValue(v)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Home Court"
                    slotProps={{
                        input: {
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {loading ? (
                                        <CircularProgress
                                            color="inherit"
                                            size={20}
                                        />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        },
                    }}
                />
            )}
            data-testid="HomeLocationAutoComplete_autoComplete"
        />
    );
}

type HomeLocationWithSelfDescribeProps = Omit<Props, "prependOther"> & {
    selfDescribedName: string | null;
    onChangeSelfDescribedName: (name: string | null) => void;
    hasSelected?: boolean;
    setHasSelected?: (hasSelected: boolean) => void;
};

export function HomeLocationWithSelfDescribe({
    homeLocationId,
    onChange,
    selfDescribedName,
    onChangeSelfDescribedName,
    hasSelected = false,
    setHasSelected = () => {},
}: HomeLocationWithSelfDescribeProps) {
    React.useEffect(() => {
        if (homeLocationId !== null && selfDescribedName) {
            onChangeSelfDescribedName("");
        }
    }, [
        homeLocationId,
        selfDescribedName,
        onChangeSelfDescribedName,
        setHasSelected,
    ]);

    return (
        <Stack spacing={2}>
            <HomeLocationAutocomplete
                homeLocationId={
                    hasSelected ? (homeLocationId ?? -1) : homeLocationId
                }
                onChange={(homeLocation) => {
                    onChange(homeLocation);
                    if (!hasSelected && setHasSelected) setHasSelected(true);
                }}
                prependOther
            />
            {homeLocationId === null && hasSelected && (
                <TextField
                    label="Please enter your club (include state)"
                    value={selfDescribedName ?? ""}
                    onChange={(e) => {
                        onChangeSelfDescribedName(e.currentTarget.value);
                    }}
                />
            )}
        </Stack>
    );
}
