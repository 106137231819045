import * as React from "react";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { MuiTelInput } from "mui-tel-input";

import type {
    LocationWithRelations,
    Profile,
    User,
    UserProfileDto,
} from "@volley/data";

import { fetchApi } from "../../util";
import CloseableDialogTitle from "../common/CloseableDialogTitle";
import { HomeLocationWithSelfDescribe } from "../common/HomeLocationAutocomplete";
import { InfoButton, VolleyButton } from "../common/buttons";
import { GENDER_OPTIONS } from "../common/data";

type FormProfile = Pick<User, "firstName" | "lastName" | "email" | "username"> &
    Pick<
        Profile,
        | "phone"
        | "gender"
        | "optedIntoMarketingAt"
        | "homeLocationId"
        | "selfDescribedHomeLocationName"
    > & {
        selfDescribedGender: string;
        birthday: string;
        platformTennisIndex: number | null;
        homeLocation: LocationWithRelations | null;
    };

type Action =
    | {
          type: "numberInput";
          payload: { key: keyof FormProfile; value: string };
      }
    | { type: "textInput"; payload: { key: keyof FormProfile; value: string } }
    | { type: "homeLocationIdInput"; value: number | null }
    | { type: "selfDescribedHomeLocationName"; value: string | null }
    | { type: "genderInput"; value: string }
    | { type: "set"; value: FormProfile };

function reducer(state: FormProfile, action: Action): FormProfile {
    switch (action.type) {
        case "numberInput": {
            const { value } = action.payload;
            const n = parseFloat(value);
            return {
                ...state,
                [action.payload.key]: Number.isNaN(n) ? null : n,
            };
        }
        case "homeLocationIdInput":
            return { ...state, homeLocationId: action.value };
        case "selfDescribedHomeLocationName":
            return { ...state, selfDescribedHomeLocationName: action.value };
        case "textInput":
            return { ...state, [action.payload.key]: action.payload.value };
        case "genderInput": {
            const selfDescribedGender =
                action.value === "self-describe"
                    ? state.selfDescribedGender
                    : "";
            return { ...state, selfDescribedGender, gender: action.value };
        }
        case "set": {
            const { gender } = action.value;

            // `selfDescribedGender` is the value for the free text input, populate with gender value if
            // gender is not one of the canned options. `gender` is the value of the drop-down, should
            // equal `self-describe` if gender is self-described otherwise it's either empty (not yet selected)
            // or one of the canned options
            const selfDescribedGender =
                gender && !GENDER_OPTIONS.map((g) => g.value).includes(gender);
            return {
                ...action.value,
                selfDescribedGender: selfDescribedGender ? gender : "",
                gender: selfDescribedGender ? "self-describe" : gender || "",
            };
        }
        default:
            throw new Error("Invalid form action type");
    }
}

const defaultState: FormProfile = {
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    phone: "",
    gender: "",
    selfDescribedGender: "",
    birthday: "",
    platformTennisIndex: 0,
    optedIntoMarketingAt: null,
    homeLocationId: null,
    selfDescribedHomeLocationName: null,
    homeLocation: null,
};

/**
 * For the form state, we flatten the object and convert nulls to empty strings
 * because input value props should not be null.
 */
const apiProfileToFormProfile = (profile: UserProfileDto): FormProfile => ({
    firstName: profile.firstName,
    lastName: profile.lastName,
    email: profile.email || "",
    username: profile.username || "",
    phone: profile.profile?.phone || "",
    gender: profile.profile?.gender || "",
    birthday: profile.profile?.birthday || "",
    selfDescribedGender: profile.profile?.gender || "",
    platformTennisIndex: profile.profile?.platformTennisIndex || null,
    optedIntoMarketingAt: profile.profile?.optedIntoMarketingAt
        ? new Date(profile.profile?.optedIntoMarketingAt)
        : null,
    homeLocationId: profile.profile?.homeLocationId || null,
    selfDescribedHomeLocationName:
        profile.profile?.selfDescribedHomeLocationName || null,
    homeLocation: profile.profile?.homeLocation ?? null,
});

interface ProfileItemProps {
    value: string;
    label: string;
    missingText?: string;
    onClickMissing?: () => void;
}
function ProfileItem({
    value,
    label,
    missingText,
    onClickMissing,
}: ProfileItemProps): React.JSX.Element {
    return (
        <ListItem
            divider
            sx={{
                my: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
            }}
        >
            <Typography
                component="dt"
                variant="caption"
                color="text.secondary"
                mb={0}
            >
                {label}
            </Typography>
            {value ? (
                <Typography component="dd">{value}</Typography>
            ) : (
                <Button color="info" onClick={onClickMissing}>
                    {missingText}
                </Button>
            )}
        </ListItem>
    );
}

export default function ProfileEdit() {
    const [state, dispatch] = React.useReducer(reducer, defaultState);
    const [loaded, setLoaded] = React.useState(false);
    const [saved, setSaved] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [updating, setUpdating] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState(false);

    const onSubmit = React.useCallback(
        async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            setUpdating(true);
            setErrorMessage("");

            const {
                firstName,
                lastName,
                gender,
                selfDescribedGender,
                email,
                username,
                ...profile
            } = state;

            const updatePayload = {
                firstName,
                lastName,
                profile: {
                    ...profile,
                    homeLocationId:
                        profile.homeLocationId && profile.homeLocationId > 0
                            ? profile.homeLocationId
                            : null,
                    platformTennisIndex: profile.platformTennisIndex || null,
                    gender: selfDescribedGender || gender,
                },
            };

            try {
                const updatedProfile = await fetchApi<UserProfileDto>(
                    "/api/users/me",
                    "PUT",
                    updatePayload,
                );
                dispatch({
                    type: "set",
                    value: apiProfileToFormProfile(updatedProfile),
                });
                setSaved(true);
                setEditOpen(false);
            } catch (er) {
                setSaved(false);
                setErrorMessage((er as Error).message);
            } finally {
                setUpdating(false);
            }
        },
        [state],
    );

    React.useEffect(() => {
        async function fetchUser() {
            const profile = await fetchApi<UserProfileDto>("/api/users/me");
            dispatch({ type: "set", value: apiProfileToFormProfile(profile) });
            setLoaded(true);
        }

        fetchUser().catch((e) => setErrorMessage((e as Error).message));
    }, []);

    const openEdit = React.useCallback(() => setEditOpen(true), []);

    if (!loaded) {
        return (
            <Box component="div" display="flex" justifyContent="center">
                <CircularProgress />
            </Box>
        );
    }

    const homeLocationLabel = state.homeLocation
        ? `${state.homeLocation.name}${
              state.homeLocation.mailingAddress
                  ? ` (${state.homeLocation.mailingAddress.city}, ${state.homeLocation.mailingAddress.state})`
                  : ""
          }`
        : `Other${state.selfDescribedHomeLocationName ? ` (${state.selfDescribedHomeLocationName})` : ""}`;

    return (
        <>
            <List>
                <ProfileItem
                    value={state.username}
                    label="Username"
                    missingText="Add Username"
                    onClickMissing={openEdit}
                />
                <ProfileItem
                    value={state.firstName}
                    label="First Name"
                    missingText="Add First Name"
                    onClickMissing={openEdit}
                />
                <ProfileItem
                    value={state.lastName}
                    label="Last Name"
                    missingText="Add Last Name"
                    onClickMissing={openEdit}
                />
                <ProfileItem value={state.email} label="Email" />
                <ProfileItem
                    value={state.birthday}
                    label="Birthdate"
                    missingText="Add Birthdate"
                    onClickMissing={openEdit}
                />
                <ProfileItem
                    value={state.gender ?? ""}
                    label="Gender"
                    missingText="Add Gender"
                    onClickMissing={openEdit}
                />
                <ProfileItem
                    value={state.phone ?? ""}
                    label="Phone Number"
                    missingText="Add Phone Number"
                    onClickMissing={openEdit}
                />
                <ProfileItem
                    value={homeLocationLabel}
                    label="Home Court"
                    missingText="Add Home Court"
                    onClickMissing={openEdit}
                />
                <ProfileItem
                    value={
                        state.platformTennisIndex
                            ? String(state.platformTennisIndex)
                            : ""
                    }
                    label="Platform Tennis Index"
                    missingText="Add Platform Tennis Index"
                    onClickMissing={openEdit}
                />
            </List>
            <Box component="div">
                <InfoButton onClick={openEdit}>Edit Your profile</InfoButton>
            </Box>
            <Dialog open={editOpen} fullWidth>
                <CloseableDialogTitle onClose={() => setEditOpen(false)}>
                    Edit your profile
                </CloseableDialogTitle>
                <DialogContent dividers>
                    <Box
                        sx={{ display: "flex", flexDirection: "column" }}
                        component="form"
                        autoComplete="off"
                        onSubmit={onSubmit}
                    >
                        <TextField
                            id="firstName"
                            label="First Name"
                            value={state.firstName ?? ""}
                            sx={{ my: 2 }}
                            onChange={(event) =>
                                dispatch({
                                    type: "textInput",
                                    payload: {
                                        key: "firstName",
                                        value: event.target.value,
                                    },
                                })
                            }
                        />
                        <TextField
                            id="lastName"
                            label="Last Name"
                            value={state.lastName ?? ""}
                            sx={{ my: 2 }}
                            onChange={(event) =>
                                dispatch({
                                    type: "textInput",
                                    payload: {
                                        key: "lastName",
                                        value: event.target.value,
                                    },
                                })
                            }
                        />

                        <TextField
                            id="birthday"
                            label="Birthday"
                            type="date"
                            sx={{ my: 2 }}
                            value={state.birthday ?? ""}
                            onChange={(event) =>
                                dispatch({
                                    type: "textInput",
                                    payload: {
                                        key: "birthday",
                                        value: event.target.value,
                                    },
                                })
                            }
                            slotProps={{
                                inputLabel: { shrink: true },
                            }}
                        />

                        <FormControl fullWidth sx={{ my: 2 }}>
                            <InputLabel id="gender-label" htmlFor="gender">
                                Gender
                            </InputLabel>
                            <Select
                                label="Gender"
                                value={state.gender ?? ""}
                                inputProps={{ id: "gender" }}
                                onChange={(event) =>
                                    dispatch({
                                        type: "genderInput",
                                        value: event.target.value,
                                    })
                                }
                            >
                                {GENDER_OPTIONS.map((g) => (
                                    <MenuItem value={g.value} key={g.value}>
                                        {g.label}
                                    </MenuItem>
                                ))}
                                <MenuItem
                                    value="self-describe"
                                    key="self-describe"
                                >
                                    Prefer to self-describe
                                </MenuItem>
                            </Select>
                        </FormControl>

                        {state.gender === "self-describe" && (
                            <TextField
                                id="self-described-gender"
                                label="Gender"
                                sx={{ my: 2 }}
                                value={state.selfDescribedGender ?? ""}
                                onChange={(event) =>
                                    dispatch({
                                        type: "textInput",
                                        payload: {
                                            key: "selfDescribedGender",
                                            value: event.target.value,
                                        },
                                    })
                                }
                            />
                        )}

                        <MuiTelInput
                            autoComplete="tel"
                            defaultCountry="US"
                            onlyCountries={["US", "CA"]}
                            forceCallingCode
                            disableDropdown
                            id="phone"
                            sx={{ my: 2 }}
                            label="Phone Number"
                            value={state.phone ?? ""}
                            onChange={(value: unknown) =>
                                dispatch({
                                    type: "textInput",
                                    payload: {
                                        key: "phone",
                                        value: value as string,
                                    },
                                })
                            }
                        />

                        <HomeLocationWithSelfDescribe
                            homeLocationId={state.homeLocationId}
                            onChange={(value) =>
                                dispatch({
                                    type: "homeLocationIdInput",
                                    value: value?.homeLocation?.id ?? null,
                                })
                            }
                            selfDescribedName={
                                state.selfDescribedHomeLocationName
                            }
                            onChangeSelfDescribedName={(value) =>
                                dispatch({
                                    type: "selfDescribedHomeLocationName",
                                    value,
                                })
                            }
                            hasSelected
                        />

                        <TextField
                            id="platformTennisIndex"
                            label="Platform Tennis Index"
                            type="number"
                            sx={{ my: 2 }}
                            value={
                                state.platformTennisIndex === null
                                    ? ""
                                    : state.platformTennisIndex
                            }
                            onChange={(event) =>
                                dispatch({
                                    type: "numberInput",
                                    payload: {
                                        key: "platformTennisIndex",
                                        value: event.target.value,
                                    },
                                })
                            }
                            slotProps={{
                                htmlInput: { step: 1 },
                            }}
                        />

                        <Box component="div" my={2} mx={2}>
                            <VolleyButton type="submit" disabled={updating}>
                                Save Changes
                            </VolleyButton>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
            <Snackbar
                autoHideDuration={5000}
                open={!!errorMessage}
                onClose={() => setErrorMessage("")}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert severity="error">
                    {`There was an error saving your profile: ${errorMessage}`}
                </Alert>
            </Snackbar>
            <Snackbar
                autoHideDuration={3000}
                open={saved}
                onClose={() => setSaved(false)}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert severity="success">Your profile was saved.</Alert>
            </Snackbar>
        </>
    );
}
