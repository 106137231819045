import * as React from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpIcon from "@mui/icons-material/Help";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { VolleyButton } from "../common/buttons";
import { useCurrentUser } from "../hooks/currentUser";
import { usePairingContext } from "../hooks/pairingStatus";
import useIntercom from "../hooks/useIntercom";

import BillingButton from "./BillingButton";
import ProfileEdit from "./Profile";

export default function UserProfile(): React.JSX.Element {
    const { currentUser, signOut } = useCurrentUser();
    const { unpair } = usePairingContext();
    const intercom = useIntercom();

    const onClickSignOut = React.useCallback(() => {
        unpair();
        signOut();
        intercom.logOut();
    }, [intercom, signOut, unpair]);

    return (
        <Stack spacing={3} pt={2}>
            <Accordion defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant="h3">Profile</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ProfileEdit />
                </AccordionDetails>
            </Accordion>
            {currentUser?.customerId && (
                <Accordion defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h3">Subscription</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <BillingButton />
                    </AccordionDetails>
                </Accordion>
            )}
            <Button
                onClick={() => {
                    intercom.newMessage();
                }}
                startIcon={<HelpIcon />}
                variant="outlined"
                color="inherit"
                fullWidth
            >
                Help
            </Button>
            <VolleyButton onClick={onClickSignOut}>Sign Out</VolleyButton>
        </Stack>
    );
}
